import React from "react";
import Hero from "./component/Hero";

import "./Main.css";
import About from "./component/About";
import Project from "./component/Project";
import Footer from "./component/Footer";

const Main = () => (
  <div className="Main">
    <Hero />
    <About />
    <Project />
    <Footer />
  </div>
);

export default Main;
