import React from "react";
import { ReactComponent as GoldMedal } from "./images/medal-golden.svg";
import { ReactComponent as BronzeMedal } from "./images/medal-bronze.svg";
import "./About.css";

const MedalType = {
  Golden: 0,
  Bronze: 1,
};

const MedalCard = ({ type, typeString, name }) => {
  const medal = type === MedalType.Golden ? <GoldMedal /> : <BronzeMedal />;
  return (
    <div className="medal-card">
      {medal}
      {name}
      <b>{typeString}</b>
    </div>
  );
};

const MedalGroupByYear = ({ year, children }) => (
  <div>
    <h1>{year}</h1>
    <div className="medal-group">{children}</div>
    <br />
  </div>
);
const Awards = () => (
  <div className="awards-container">
    <h1>Awards</h1>
    <MedalGroupByYear year="2009">
      <MedalCard
        type={MedalType.Golden}
        name={`全國高中職學校商業類\n科技藝競賽網頁設計類`}
        typeString="金手獎"
      ></MedalCard>
    </MedalGroupByYear>
    <MedalGroupByYear year="2017">
      <MedalCard
        type={MedalType.Bronze}
        name={`國際奧林匹亞\n電腦對局競賽 Block Go 項目`}
        typeString="銅牌"
      ></MedalCard>
      <MedalCard
        type={MedalType.Golden}
        name={`台灣電腦對局學會\n電腦對局競賽 Block Go 項目`}
        typeString="金牌"
      ></MedalCard>
    </MedalGroupByYear>
    <MedalGroupByYear year="2018">
      <MedalCard
        type={MedalType.Golden}
        name={`國際奧林匹亞\n電腦對局競賽 Block Go 項目`}
        typeString="金牌"
      ></MedalCard>
      <MedalCard
        type={MedalType.Golden}
        name={`中華民國人工智慧學會\n電腦對局競賽 Block Go 項目`}
        typeString="金牌"
      ></MedalCard>
      <MedalCard
        type={MedalType.Golden}
        name={`中華民國人工智慧學會\n電腦對局競賽\nDeep Learning Othello 10x10 項目`}
        typeString="金牌"
      ></MedalCard>
    </MedalGroupByYear>
  </div>
);

const Introduce = () => (
  <div className="introduce-container">
    <p>你好，我是一位軟體工程師，我叫阿剛。</p>
    <p>
      我在 2017
      年於花蓮國立東華大學資訊工程所的人工智慧實驗室取得碩士學位，而過去曾在母校的人工智慧實驗室任職軟體工程師，有著3
      年的 Web APP
      前端開發的經驗；主要開發的產品是將機器學習技術應用在圍棋教學上。
      而我所任職的單位雖然只是一個 10
      人的小團隊，但每個人都各司其職、合作無間；成員中包含日本棋院的旅日職業棋士九段王銘琬、趨勢科技的資料科學家、UI
      以及UX的設計師，可以說是跨國界的團隊。該產品上線後成功在台灣推廣，並有效的降底圍棋入門的學習門檻。總之，我迫不及待地想與你共事，請和我聯絡吧!
    </p>
  </div>
);
const About = () => (
  <div className="about-container">
    <Introduce />
    <Awards />
  </div>
);

export default About;
