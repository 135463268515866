import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as Arrow } from "./images/arrow.svg";
import "./Button.css";

const GotoButton = ({ children, color }) => (
  <button className="goto-button" style={{ color, borderColor: color }}>
    {children} <Arrow fill={color} />
  </button>
);

GotoButton.defaultProps = {
  children: null,
  color: "black",
};

GotoButton.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export default GotoButton;
