import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

import "./Footer.css";

const Footer = () => (
  <footer>
    <a id="email" href="mailto:me@chihkanglo.info">
      <FontAwesomeIcon icon={faEnvelope} size="lg" />
    </a>
    <a
      id="facebook"
      href="https://www.facebook.com/jhihkang.luo"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon id="facebook" icon={faFacebook} size="lg" />
    </a>
    <a
      id="twitter"
      href="https://twitter.com/AgangLuo?ref_src=twsrc%5Etfw"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon id="twitter" icon={faTwitter} size="lg" />
    </a>
  </footer>
);

export default Footer;
