import React from "react";

import "./Hero.css";
import heroImage from "./images/hero_image.png";
import logo from "./images/logo.png";
import GotoButton from "./Button";

const Logo = () => (
  <div className="logo" style={{ backgroundImage: `url(${logo})` }}></div>
);
const Hero = () => (
  <div className="hero-container">
    <div
      className="hero-background"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <Logo />
      <div className="hero-content">
        <p>
          HI! <br />
          MY NAME IS CHIHKANG LO <br />
          I’M A WEB DEVELOPER
        </p>
        <a href="#project">
          <GotoButton color="#5a4c7b">View project</GotoButton>
        </a>
      </div>
    </div>
  </div>
);

export default Hero;
