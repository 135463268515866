import React from "react";
import jungoPreview from "./images/jungo_review.png";
import taaiPreview from "./images/taai.png";
import aiforumPreview from "./images/aiforum.png";
import blockgoPreview from "./images/blockgo.png";
import jungoLogo from "./images/jungo.png";
import "./Project.css";
import GotoButton from "./Button";

const JungoProject = () => (
  <div
    id="project"
    className="jungo-project"
    style={{ backgroundImage: `url(${jungoPreview})` }}
  >
    <div className="jungo-details">
      <img src={jungoLogo} alt="Jungo" />
      <p>用純棋，10分鐘學會圍棋</p>
      <a
        href="https://jungo.sinkirou.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <GotoButton color="#557D7E">Live preview</GotoButton>
      </a>
    </div>
  </div>
);

const TaaiProject = () => (
  <div className="taai-project">
    <img src={taaiPreview} alt="TAAI preview" />
    <a
      href="https://tcga.tw/taai2017"
      target="_blank"
      rel="noopener noreferrer"
    >
      <GotoButton color="#89C1C2">Live preview</GotoButton>
    </a>
  </div>
);

const AiforumProject = () => (
  <div className="aiforum-project">
    <img src={aiforumPreview} alt="AIFORUM preview" />
    <a
      href="http://aiforum.csie.ndhu.edu.tw/aiforum2016"
      target="_blank"
      rel="noopener noreferrer"
    >
      <GotoButton color="#557D7E">Live preview</GotoButton>
    </a>
  </div>
);

const BlockgoProject = () => (
  <div className="blockgo-project">
    <img src={blockgoPreview} alt="Blockgo preview" />
    <p>應用深度學習技術開發的版塊圍棋 Web App</p>
    <a
      href="https://git.serv.ai.csie.ndhu.edu.tw/chihkanglo/docker-owlo-block-go"
      target="_blank"
      rel="noopener noreferrer"
    >
      <GotoButton color="#ffffff">Source Code</GotoButton>
    </a>
  </div>
);

const Project = () => (
  <div className="project-container">
    <JungoProject />
    <TaaiProject />
    <AiforumProject />
    <BlockgoProject />
  </div>
);

export default Project;
